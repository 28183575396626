import {
    getLogistics,
} from '@/utils/order.js';
import useClipboard from "vue-clipboard3"
export default {
    data() {
        return {
            orderId: '',
            logisticsInfo: '',
        }
    },
    created() {
        this.orderId = this.$route.query.orderId;
        this.getLogisticsFunc();
    },
    methods: {
        // 获取订单详情
        getLogisticsFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                orderId: this.orderId
            }
            getLogistics(data).then(res => {
                console.log('detail', res)
                this.logisticsInfo = res.data
            })
        },
        // 复制订单号
        async copyFun() {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(this.logisticsInfo.express_no)
                this.$toast.success("已复制物流单号")
            } catch (e) {
                console.error(e)
            }
        },
    },
}