<template>
    <div class="logistics-info">
        <div class="l-box flex">
            <img src="@/assets/img/order/after/car.png" alt="" class="car">
            <div class="l-h">
                <p class="f-f">{{logisticsInfo.express_name}}</p>
                <div class="c-c flex">
                    <p>物流单号：{{logisticsInfo.express_no}}</p>
                    <img src="@/assets/img/order/after/copy.png" alt="" @click="copyFun">
                </div>
                <p class="b-t">预计到达时间：{{logisticsInfo.deliver_time}}</p>
            </div>
        </div>
    </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>